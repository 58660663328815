<template>
  <div class="privacy-container">
    <h1>Datenschutz/Datenschutzbelehrung</h1>
    Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen
    Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
    Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf. (nachfolgend
    gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
    „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung
    (DSGVO).<br/> <br/>
    Verantwortlicher: <br/><br/>
    Name/Fa.: Supertracker GmbH <br/>
    Straße Nr.: Zellerberg 2/888 <br/>
    PLZ, Ort, Land: 6330 Kufstein, Österreich <br/>
    UID: ATU71324639 <br/>
    Telefonnummer:<br/>
    E-Mail-Adresse: <a href="mailto:office@supertracker.eu">office@supertracker.eu</a> <br/> <br/>

    Datenschutzbeauftragter: <br/> <br/>

    Name: <br/>
    Straße Nr.: Zellerberg 2/888 <br/>
    PLZ, Ort, Land: 6330 Kufstein, Österreich <br/>
    UID: ATU69816336 <br/>
    Telefonnummer: <br/>
    E-Mail-Adresse: <a href="mailto:office@supertracker.eu">office@supertracker.eu</a> <br/><br/>

    Arten der verarbeiteten Daten: <br/>
    – Bestandsdaten (z.B., Namen, Adressen). <br/>
    – Kontaktdaten (z.B., E-Mail, Telefonnummern). <br/>
    – Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos). <br/>
    – Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie). <br/>
    – Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie). <br/>
    – Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). <br/>
    – Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen). <br/> <br/>

    Verarbeitung besonderer Kategorien von Daten (Art. 9 Abs. 1 DSGVO): <br/> <br/>

    Es werden keine besonderen Kategorien von Daten verarbeitet. <br/> <br/>

    Kategorien der von der Verarbeitung betroffenen Personen: <br/> <br/>

    – Kunden, Interessenten, Besucher und Nutzer des Onlineangebotes, Geschäftspartner.<br/>
    – Besucher und Nutzer des Onlineangebotes.<br/>
    Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“.<br/><br/>

    Zweck der Verarbeitung:<br/>

    – Zurverfügungstellung des Onlineangebotes, seiner Inhalte und Shop-Funktionen. <br/>
    – Erbringung vertraglicher Leistungen, Service und Kundenpflege. <br/>
    – Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern. <br/>
    – Marketing, Werbung und Marktforschung. <br/>
    – Sicherheitsmaßnahmen. <br/> <br/>

    Stand: 05/2021 <br/> <br/>

    <h4>1. Verwendete Begrifflichkeiten</h4>

    1.1. „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
    natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person
    angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
    Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
    Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
    wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind. <br/> <br/>

    1.2. „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche
    Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden
    Umgang mit Daten. <br/> <br/>

    1.3. Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
    die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
    entscheidet, bezeichnet. <br/> <br/>

    <h4>2. Maßgebliche Rechtsgrundlagen</h4>
    Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
    Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die
    Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
    zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist
    Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
    Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
    berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der
    betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich
    machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage. <br/> <br/>

    <h4>3. Änderungen und Aktualisierungen der Datenschutzerklärung</h4>
    Wir bitten Sie sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
    Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich
    machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung)
    oder eine sonstige individuelle Benachrichtigung erforderlich wird. <br/> <br/>

    <h4>4. Sicherheitsmaßnahmen</h4>
    4.1. Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der
    Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
    unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher
    Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
    gewährleisten; Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
    Verfügbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden
    Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
    Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung
    der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung,
    bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch
    Technikgestaltung und durch datenschutzfreundliche Voreinstellungen berücksichtigt (Art. 24 DSGVO). <br/> <br/>

    4.2. Zu den Sicherheitsmaßnahmen gehört insbesondere die verschlüsselte Übertragung von Daten zwischen Ihrem
    Browser und unserem Server. <br/> <br/>

    <h4>5. Offenlegung und Übermittlung von Daten</h4>
    5.1. Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen
    (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten
    gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
    Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist),
    Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten
    Interessen (z.B. beim Einsatz von Beauftragten, Hostinganbietern, Steuer-, Wirtschafts- und Rechtsberatern,
    Kundenpflege-, Buchführungs-, Abrechnungs- und ähnlichen Diensten, die uns eine effiziente und effektive Erfüllung
    unserer Vertragspflichten, Verwaltungsaufgaben und Pflichten erlauben). <br/> <br/>

    5.2. Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“
    beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO. <br/> <br/>

    <h4>6. Übermittlungen in Drittländer</h4>
    Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
    Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung,
    bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen
    Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer
    berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder
    lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO
    verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten
    Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder
    Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen
    (so genannte „Standardvertragsklauseln“). <br/> <br/>

    <h4>7. Rechte der betroffenen Personen</h4>
    7.1. Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
    Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend
    Art. 15 DSGVO. <br/> <br/>

    7.2. Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die
    Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen. <br/> <br/>

    7.3. Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht
    werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der
    Daten zu verlangen. <br/> <br/>

    7.4. Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
    Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern. <br/> <br/>

    7.5. Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
    einzureichen. <br/> <br/>

    <h4>8. Widerrufsrecht</h4>
    Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu
    widerrufen. <br/> <br/>

    <h4>9. Widerspruchsrecht</h4>
    Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
    widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
    Direktwerbung erfolgen. <br/> <br/>

    <h4>10. Cookies und Widerspruchsrecht bei Direktwerbung</h4>
    10.1. Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb
    der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem
    Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines
    Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden
    Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser
    schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status
    gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des
    Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach
    mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden,
    die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden Cookies von
    anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, bezeichnet (andernfalls, wenn es nur
    dessen Cookies sind spricht man von „First-Party Cookies“). <br/> <br/>

    10.2. Wir setzen temporäre und permanente Cookies ein und klären hierüber im Rahmen unserer
    Datenschutzerklärung auf. <br/> <br/>

    Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
    entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den
    Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
    dieses Onlineangebotes führen. <br/> <br/>

    10.3. Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann
    bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite
    <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a> oder die EU-Seite
    <a href="http://www.youronlinechoices.com/erklärt" target="_blank">http://www.youronlinechoices.com/erklärt</a>
    werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers
    erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
    genutzt werden können. <br/> <br/>

    <h4>11. Löschung von Daten</h4>
    11.1. Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer
    Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
    bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
    Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie
    für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten
    werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
    steuerrechtlichen Gründen aufbewahrt werden müssen. <br/> <br/>

    11.2. Deutschland: Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung insbesondere für 6 Jahre gemäß § 247 Abs.
    1 HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege, etc.) sowie
    für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und
    Geschäftsbriefe, für Besteuerung relevante Unterlagen, etc. <br/> <br/>

    <h4>12. Bestellabwicklung im Onlineshop und Kundenkonto</h4>
    12.1. Wir verarbeiten die Daten unserer Kunden im Rahmen der Bestellvorgänge in unserem Onlineshop, um ihnen die
    Auswahl und die Bestellung der gewählten Produkte und Leistungen, sowie deren Bezahlung und Zustellung, bzw.
    Ausführung zu ermöglichen. <br/> <br/>

    12.2. Zu den verarbeiteten Daten gehören Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten und zu
    den betroffenen Personen unsere Kunden, Interessenten und sonstige Geschäftspartner. Die Verarbeitung erfolgt zum
    Zweck der Erbringung von Vertragsleistungen im Rahmen des Betriebs eines Onlineshops, Abrechnung, Auslieferung und
    der Kundenservices. Hierbei setzen wir Session Cookies für die Speicherung des Warenkorb-Inhalts und permanente
    Cookies für die Speicherung des Login-Status ein. <br/> <br/>

    12.3. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b (Durchführung Bestellvorgänge) und c
    (Gesetzlich erforderliche Archivierung) DSGVO. Dabei sind die als erforderlich gekennzeichneten Angaben zur
    Begründung und Erfüllung des Vertrages erforderlich. Die Daten offenbaren wir gegenüber Dritten nur im Rahmen der
    Auslieferung, Zahlung oder im Rahmen der gesetzlichen Erlaubnisse und Pflichten gegenüber Rechtsberatern und
    Behörden. Die Daten werden in Drittländern nur dann verarbeitet, wenn dies zur Vertragserfüllung erforderlich
    ist (z.B. auf Kundenwunsch bei Auslieferung oder Zahlung). <br/> <br/>

    12.4. Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere ihre Bestellungen einsehen können.
    Im Rahmen der Registrierung, werden die erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die Nutzerkonten
    sind nicht öffentlich und können von Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt
    haben, werden deren Daten im Hinblick auf das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung ist aus
    handels- oder steuerrechtlichen Gründen entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig. Angaben im Kundenkonto
    verbleiben bis zu dessen Löschung mit anschließender Archivierung im Fall einer rechtlichen Verpflichtung. Es
    obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. <br/> <br/>

    12.5. Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer Onlinedienste, speichern
    wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer
    berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
    Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche
    erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO. <br/> <br/>

    12.6. Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, die
    Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
    Archivierungspflichten erfolgt die Löschung nach deren Ablauf (Ende handelsrechtlicher (6 Jahre) und
    steuerrechtlicher (10 Jahre) Aufbewahrungspflicht); Angaben im Kundenkonto verbleiben bis zu
    dessen Löschung. <br/> <br/>

    <h4>13. Betriebswirtschaftliche Analysen und Marktforschung</h4>

    13.1. Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Kunden- und Nutzerwünsche erkennen zu können,
    analysieren wir die uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc. Wir verarbeiten
    dabei Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf Grundlage
    des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen Personen Kunden, Interessenten, Geschäftspartner,
    Besucher und Nutzer des Onlineangebotes gehören. Die Analysen erfolgen zum Zweck Betriebswirtschaftliche
    Auswertungen, des Marketings und der Marktforschung. Dabei können wir die Profile der registrierten Nutzer mit
    Angaben z.B. zu deren Kaufvorgängen berücksichtigen. Die Analysen dienen uns zur Steigerung der
    Nutzerfreundlichkeit, der Optimierung unseres Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen
    alleine uns und werden nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten
    Werten handelt. <br/> <br/>

    13.2. Sofern diese Analysen oder Profile personenbezogen sind, werden sie mit Kündigung der Nutzer gelöscht oder
    anonymisiert, sonst nach zwei Jahren ab Vertragsschluss. Im Übrigen werden die gesamtbetriebswirtschaftlichen
    Analysen und allgemeine Tendenzbestimmungen nach Möglichkeit anonym erstellt. <br/> <br/>

    2. Die Prüfung der Bonität eines Kunden ist dann zulässig, wenn ansonsten die Gefahr des Zahlungsausfalls droht,
    d.h. wenn die Ware geliefert wird, ohne dass die Zahlung eingetroffen ist (d.h. wenn der Kunde den Kauf auf
    Rechnung wählt). Kein Zahlungsausfall droht dagegen, wenn der Kunden z.B. die Option Vorkasse wählt oder die
    Zahlung über Drittanbieter, wie z.B. Paypal durchführt. <br/> <br/>

    Zu beachten ist ferner, dass die Einholung einer automatischen Bonitätsauskunft eine „Automatisierte Entscheidungen
    im Einzelfall“ gem. Art. 22 DSGVO, d.h. eine rechtliche Entscheidung ohne menschliche Mitwirkung, darstellt. Diese
    ist zulässig, wenn der Kunde eingewilligt hat oder diese Entscheidung für den Vertragsschluss erforderlich ist. Ob
    die Entscheidung erforderlich ist, ist noch nicht abschließend geklärt, wird jedoch vielfach als gegeben vertreten,
    auch von dem Autor dieses Musters. Wenn Sie jedoch jegliches Risiko ausschließen möchten, sollten Sie eine
    Einwilligung einholen. <br/> <br/>

    Eine Einwilligung wird auch dann notwendig, wenn die Bonitätsauskunft schon bereits dann eingesetzt wird, um
    überhaupt zu entscheiden, ob die Option „auf Rechnung“ eingeblendet werden soll. Denn es hätte sein können, dass
    der Kunde sich ohnehin für die Vorkasse oder Paypal entschieden hätte und die Bonitätsprüfung nicht erforderlich
    gewesen wäre. <br/> <br/>

    Eine solche Einwilligung könne z.B. wie folgt lauten: <br/> <br/>

    Ich erkläre mich damit einverstanden, dass eine Bonitätsüberprüfung durchgeführt wird, um in einem automatisierten
    Verfahren (Art. 22 DSGVO) zu entscheiden, ob die Option des Kaufs auf Rechnung angeboten wird. Weitere
    Informationen zur Bonitätsprüfung, den eingesetzten Wirtschaftsauskunfteien und dem Verfahren sowie den
    Widerspruchsmöglichkeiten, erhalten Sie in unserer Datenschutzerklärung. <br/> <br/>

    <h4>14. Bonitätsauskunft</h4>

    14.1. Sofern wir in Vorleistung treten (z.B. beim Kauf auf Rechnung), behalten wir uns vor, zur Wahrung der
    berechtigten Interessen eine Identitäts- und Bonitätsauskunft zwecks Beurteilung des Kreditrisikos auf Basis von
    mathematisch-statistischen Verfahren von hierauf spezialisierten Dienstleistungsunternehmen
    (Wirtschaftsauskunfteien) einzuholen. <br/> <br/>

    14.2. Die von den Wirtschaftsauskunfteien erhaltenen Informationen über die statistische Wahrscheinlichkeit eines
    Zahlungsausfalls verarbeiten wir im Rahmen einer sachgerechten Ermessensentscheidung über die Begründung,
    Durchführung und Beendigung des Vertragsverhältnisses. Wir behalten uns vor, im Fall eines negativen Ergebnisses
    der Bonitätsprüfung, die Zahlung auf Rechnung oder eine andere Vorleistung zu verweigern. <br/> <br/>

    14.3. Die Entscheidung, ob wir in Vorleistung treten, erfolgt entsprechend Art. 22 DSGVO alleine auf Grundlage
    einer automatisierten Entscheidung im Einzelfall, die unsere Software unter Zugrundelegung der Auskunft der
    Wirtschaftsauskunftei vornimmt. <br/> <br/>

    14.4 Sofern wir eine ausdrückliche Einwilligung von Ihnen einholen, ist die Rechtsgrundlage für die
    Bonitätsauskunft und die Übermittlung der Daten des Kunden an die Auskunfteien die Einwilligung gem. Art. 6 Abs.
    1 lit. a, 7 DSGVO. Falls keine Einwilligung eingeholt wird, sind unsere berechtigten Interessen an der
    Ausfallsicherheit ihrer Zahlungsforderung die Rechtsgrundlage gem. Art. 6 Abs. 1 lit. f. DSGVO. <br/> <br/>

    <h4>15. Kontaktaufnahme und Kundenservice</h4>

    15.1. Bei der Kontaktaufnahme mit uns (per Kontaktformular oder E-Mail) werden die Angaben des Nutzers zur
    Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. <br/> <br/>

    15.2. Die Angaben der Nutzer können in unserem Customer-Relationship-Management System („CRM System“) oder
    vergleichbarer Anfragenorganisation gespeichert werden. <br/> <br/>

    15.3. Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit
    alle zwei Jahre; Anfragen von Kunden, die über ein Kundenkonto verfügen, speichern wir dauerhaft und verweisen zur
    Löschung auf die Angaben zum Kundenkonto. Ferner gelten die gesetzlichen Archivierungspflichten. <br/> <br/>

    <h4>16. Erhebung von Zugriffsdaten und Logfiles</h4>

    16.1. Wir erheben auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
    über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den
    Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge,
    Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL
    (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider. <br/> <br/>

    16.2. Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
    Betrugshandlungen) für die Dauer von maximal sieben Tagen gespeichert und danach gelöscht. Daten, deren weitere
    Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der
    Löschung ausgenommen. <br/> <br/>

    <h4>17. Onlinepräsenzen in sozialen Medien</h4>

    17.1. Wir unterhalten auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
    Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und
    Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können. Beim Aufruf der jeweiligen
    Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen
    Betreiber. <br/> <br/>

    17.2 Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer
    sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren
    Onlinepräsenzen verfassen oder uns Nachrichten zusenden. <br/> <br/>

    17.3. Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner Partner
    geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt
    haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten
    Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog. „Remarketing-“, bzw.
    „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchten wir auch sicherstellen, dass unsere
    Anzeigen dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. <br/> <br/>

    <h4>18. Google Analytics</h4>

    18.1. Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
    wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics, einen
    Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten
    Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google
    in den USA übertragen und dort gespeichert. <br/> <br/>

    18.2. Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
    Datenschutzrecht einzuhalten
    (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank">
    https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>). <br/> <br/>

    18.3. Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die
    Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um
    weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber
    zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer
    erstellt werden. <br/> <br/>

    18.4. Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der
    Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
    Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
    Server von Google in den USA übertragen und dort gekürzt. <br/> <br/>

    18.5. Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
    zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer
    Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
    ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google
    verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
      https://tools.google.com/dlpage/gaoptout?hl=de</a>. <br/> <br/>

    18.6. Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie
    auf den Webseiten von Google: https://www.google.com/intl/de/policies/privacy/partners („Datennutzung durch Google
    bei Ihrer Nutzung von Websites oder Apps unserer Partner“), https://policies.google.com/technologies/ads
    („Datennutzung zu Werbezwecken“), https://adssettings.google.com/authenticated(„Informationen verwalten, die
    Google verwendet, um Ihnen Werbung einzublenden“). <br/> <br/>

    <h4>19. Google-Re/Marketing-Services</h4>

    19.1. Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
    wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Marketing- und
    Remarketing-Dienste (kurz „Google-Marketing-Services”) der Google LLC, 1600 Amphitheatre Parkway, Mountain View,
    CA 94043, USA, („Google“). <br/> <br/>

    19.2. Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
    europäische Datenschutzrecht einzuhalten
    (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank">
    https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>). <br/> <br/>

    19.3. Die Google-Marketing-Services erlauben uns Werbeanzeigen für und auf unserer Website gezielter anzuzeigen,
    um Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B.
    Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Webseiten interessiert hat, spricht man hierbei
    vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen
    Google-Marketing-Services aktiv sind, unmittelbar durch Google ein Code von Google ausgeführt und es werden sog.
    (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als „Web Beacons“ bezeichnet) in die Webseite eingebunden.
    Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine Datei abgespeichert
    (statt Cookies können auch vergleichbare Technologien verwendet werden). Die Cookies können von verschiedenen
    Domains gesetzt werden, unter anderem von google.com, doubleclick.net, invitemedia.com, admeld.com,
    googlesyndication.com oder googleadservices.com. In dieser Datei wird vermerkt, welche Webseiten der Nutzer
    aufgesucht, für welche Inhalte er sich interessiert und welche Angebote er geklickt hat, ferner technische
    Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur
    Nutzung des Onlineangebotes. Es wird ebenfalls die IP-Adresse der Nutzer erfasst, wobei wir im Rahmen von
    Google-Analytics mitteilen, dass die IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in
    anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und nur in Ausnahmefällen
    ganz an einen Server von Google in den USA übertragen und dort gekürzt wird. Die IP-Adresse wird nicht mit Daten
    des Nutzers innerhalb von anderen Angeboten von Google zusammengeführt. Die vorstehend genannten Informationen
    können seitens Google auch mit solchen Informationen aus anderen Quellen verbunden werden. Wenn der Nutzer
    anschließend andere Webseiten besucht, können ihm entsprechend seiner Interessen die auf ihn abgestimmten Anzeigen
    angezeigt werden. <br/> <br/>

    19.4. Die Daten der Nutzer werden im Rahmen der Google-Marketing-Services pseudonym verarbeitet. D.h. Google
    speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten
    Daten Cookie-bezogen innerhalb pseudonymer Nutzer-Profile. D.h. aus der Sicht von Google werden die Anzeigen nicht
    für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon
    wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne
    diese Pseudonymisierung zu verarbeiten. Die von Google-Marketing-Services über die Nutzer gesammelten Informationen
    werden an Google übermittelt und auf Googles Servern in den USA gespeichert. <br/> <br/>

    19.5. Zu den von uns eingesetzten Google-Marketing-Services gehört u.a. das Online-Werbeprogramm „Google AdWords“.
    Im Fall von Google AdWords, erhält jeder AdWords-Kunde ein anderes „Conversion-Cookie“. Cookies können somit nicht
    über die Websites von AdWords-Kunden nachverfolgt werden. Die mit Hilfe des Cookies eingeholten Informationen
    dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden
    haben. Die AdWords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer
    mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen,
    mit denen sich Nutzer persönlich identifizieren lassen. <br/> <br/>

    19.6. Wir können auf Grundlage des Google-Marketing-Services „DoubleClick“ Werbeanzeigen Dritter einbinden.
    DoubleClick verwendet Cookies, mit denen Google und seinen Partner-Websites, die Schaltung von Anzeigen auf Basis
    der Besuche von Nutzern auf dieser Website bzw. anderen Websites im Internet ermöglicht wird. <br/> <br/>

    19.7. Wir können auf Grundlage des Google-Marketing-Services „AdSense“ Werbeanzeigen Dritter einbinden. AdSense
    verwendet Cookies, mit denen Google und seinen Partner-Websites, die Schaltung von Anzeigen auf Basis der Besuche
    von Nutzern auf dieser Website bzw. anderen Websites im Internet ermöglicht wird. <br/> <br/>

    19.8. Ebenfalls können wir den Dienst „Google Optimizer“ einsetzen. Google Optimizer erlaubt uns im Rahmen so
    genannten „A/B-Testings“ nachzuvollziehen, wie sich verschiedene Änderungen einer Website auswirken (z.B.
    Veränderungen der Eingabefelder, des Designs, etc.). Für diese Testzwecke werden Cookies auf den Geräten der
    Nutzer abgelegt. Dabei werden nur pseudonyme Daten der Nutzer verarbeitet. <br/> <br/>

    19.9. Ferner können wir den „Google Tag Manager“ einsetzen, um die Google Analyse- und Marketing-Dienste in
    unsere Website einzubinden und zu verwalten. <br/> <br/>

    19.10. Weitere Informationen zur Datennutzung zu Marketingzwecken durch Google, erfahren Sie auf der
    Übersichtsseite: <a href="https://policies.google.com/technologies/ads" target="_blank">
    https://policies.google.com/technologies/ads</a>. <br/> <br/>

    19.11. Wenn Sie der interessensbezogenen Werbung durch Google-Marketing-Services widersprechen möchten, können Sie
    die von Google gestellten Einstellungs- und Opt-Out-Möglichkeiten nutzen:
    <a href="https://adssettings.google.com/authenticated" target="_blank">
      https://adssettings.google.com/authenticated</a>. <br/> <br/>

    Des Weiteren nutzen wir beim Einsatz des Facebook-Pixels die Zusatzfunktion „erweiterter Abgleich“ (hierbei werden
    Daten wie Telefonnummern, E-Mailadressen oder Facebook-IDs der Nutzer) zur Bildung von Zielgruppen
    („Custom Audiences“ oder „Look Alike Audiences“) an Facebook (verschlüsselt) übermittelt. Weitere Hinweise zum
    „erweiterten Abgleich“: <a href="https://www.facebook.com/business/help/611774685654668" target="_blank">
    https://www.facebook.com/business/help/611774685654668</a>). <br/> <br/>

    Wir nutzen ebenfalls das Verfahren „Custom Audiences from File“ des sozialen Netzwerks Facebook, Inc. In diesem
    Fall werden die E-Mail-Adressen der Newsletterempfänger bei Facebook hochgeladen. Der Upload-Vorgang findet
    verschlüsselt statt. Der Upload dient alleine, um Empfänger unserer Facebook-Anzeigen zu bestimmen. Wir möchten
    damit sicherstellen, dass die Anzeigen nur Nutzern angezeigt werden, die ein Interesse an unseren Informationen
    und Leistungen haben. <br/> <br/>

    Hinweis zum Opt-Out: Beachten Sie bitte, dass Facebook im Zeitpunkt der Erstellung dieses Musters kein Opt-Out
    anbietet und Sie es selbst implementieren müssen. Falls Sie es nicht tun, müssen Sie diesen Passus entfernen. Die
    Umsetzung kann z.B. mittels Javascript (Setzen des Opt-Out-Links) und beim Laden der Seite via PHP (das prüft, ob
    das Opt-Out-Cookies gesetzt wurde und nur beim negativen Ergebnis das Facebook-Pixel lädt) erfolgen. Besucht ein
    Nutzer die Website, muss geprüft werden, ob das “Opt-Out“-Cookie gesetzt ist. Falls ja, darf das „Facebook-Pixel“
    nicht geladen werden. <br/> <br/>

    <h4>20. Facebook-, Custom Audiences und Facebook-Marketing-Dienste</h4>

    20.1. Innerhalb unseres Onlineangebotes wird aufgrund unserer berechtigten Interessen an Analyse, Optimierung und
    wirtschaftlichem Betrieb unseres Onlineangebotes und zu diesen Zwecken das sog. „Facebook-Pixel“ des sozialen
    Netzwerkes Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94024, USA, bzw. falls Sie in der
    EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben
    wird („Facebook“), eingesetzt. <br/> <br/>

    20.2. Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
    europäische Datenschutzrecht einzuhalten
    (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank">
    https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>). <br/> <br/>

    20.3. Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als
    Zielgruppe für die Darstellung von Anzeigen (sog. „Facebook-Ads“) zu bestimmen. Dementsprechend setzen wir das
    Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch
    ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten
    Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook
    übermitteln (sog. „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere
    Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des
    Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
    Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf
    unsere Website weitergeleitet wurden (sog. „Conversion“). <br/> <br/>

    20.4. Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie.
    Dementsprechend generelle Hinweise zur Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von
    Facebook: <a href="https://www.facebook.com/policy.php" target="_blank">https://www.facebook.com/policy.php</a>.
    Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie im Hilfebereich von
    Facebook: <a href="https://www.facebook.com/business/help/651294705016616" target="_blank">
    https://www.facebook.com/business/help/651294705016616</a>. <br/> <br/>

    20.5. Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads
    widersprechen. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden,
    können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen
    nutzungsbasierter Werbung befolgen: https://www.facebook.com/settings?tab=ads. Die Einstellungen erfolgen
    plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile
    Geräte übernommen. <br/> <br/>

    20.6. Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, ferner über die
    Deaktivierungsseite der Netzwerkwerbeinitiative (<a href="http://optout.networkadvertising.org/" target="_blank">
    http://optout.networkadvertising.org/</a>) und zusätzlich die US-amerikanische Webseite
    (<a href="http://www.aboutads.info/choices" target="_blank">http://www.aboutads.info/choices</a>) oder die
    europäische Webseite (<a href="http://www.youronlinechoices.com/uk/your-ad-choices/" target="_blank">
    http://www.youronlinechoices.com/uk/your-ad-choices/</a>) widersprechen. <br/> <br/>

    <h4>21. Facebook Social Plugins</h4>

    21.1. Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
    wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins
    („Plugins“) des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square,
    Grand Canal Harbour, Dublin 2, Irland betrieben wird („Facebook“). Die Plugins können Interaktionselemente oder
    Inhalte (z.B. Videos, Grafiken oder Textbeiträge) darstellen und sind an einem der Facebook Logos erkennbar
    (weißes „f“ auf blauer Kachel, den Begriffen „Like“, „Gefällt mir“ oder einem „Daumen hoch“-Zeichen) oder sind mit
    dem Zusatz „Facebook Social Plugin“ gekennzeichnet. Die Liste und das Aussehen der Facebook Social Plugins kann
    hier eingesehen werden: <a href="https://developers.facebook.com/docs/plugins/" target="_blank">
    https://developers.facebook.com/docs/plugins/</a>. <br/> <br/>

    21.2. Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
    europäische Datenschutzrecht einzuhalten
    (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank">
    https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>). <br/> <br/>

    21.3. Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enthält, baut sein Gerät
    eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an das
    Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können aus den verarbeiteten
    Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die
    Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher entsprechend
    unserem Kenntnisstand. <br/> <br/>

    21.4. Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite des
    Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
    Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen oder
    einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook übermittelt und
    dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook
    seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine anonymisierte
    IP-Adresse gespeichert. <br/> <br/>

    21.5. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
    die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können diese den
    Datenschutzhinweisen von Facebook entnehmen: <a href="https://www.facebook.com/about/privacy/" target="_blank">
    https://www.facebook.com/about/privacy/</a>. <br/> <br/>

    21.6. Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten über ihn
    sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der Nutzung unseres
    Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und Widersprüche zur
    Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich:
    <a href="https://www.facebook.com/settings?tab=ads" target="_blank">https://www.facebook.com/settings?tab=ads</a>
    oder über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/" target="_blank">
    http://www.aboutads.info/choices/</a>oder die EU-Seite <a href="http://www.youronlinechoices.com/" target="_blank">
    http://www.youronlinechoices.com/</a>. Die Einstellungen erfolgen plattformunabhängig, d.h. sie
    werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen. <br/> <br/>

    <h4>22. Leadinfo</h4>

    Wir nutzen den Lead-Generation-Service von Leadinfo B.V., Rotterdam, Niederlande. Dieser erkennt Besuche von
    Unternehmen auf unserer Website anhand von IP-Adressen und zeigt uns hierzu öffentlich verfügbare Informationen,
    wie z.B. Firmennamen oder Adressen. Darüber hinaus setzt Leadinfo zwei First-Party-Cookies zur Auswertung des
    Nutzerverhaltens auf unserer Website und verarbeitet Domains aus Formulareingaben (z.B. „leadinfo.com“), um
    IP-Adressen mit Unternehmen zu korrelieren und die Services zu verbessern. Weitere Informationen finden Sie
    unter www.leadinfo.com. Auf dieser Seite: www.leadinfo.com/en/opt-out haben Sie eine Opt-out Möglichkeit. Im
    Falle eines Opt-outs werden Ihre Daten von Leadinfo nicht mehr erfasst. <br/> <br/>

    <h4>23. Kommunikation via Post, E-Mail, Fax oder Telefon</h4>

    23.1 Wir nutzen für die Geschäftsabwicklung und für Marketingzwecke Fernkommunikationsmittel, wie z.B. Post,
    Telefon oder E-Mail. Dabei verarbeiten wir Bestandsdaten, Adress- und Kontaktdaten sowie Vertragsdaten von Kunden,
    Teilnehmern, Interessenten und Kommunikationspartner. <br/> <br/>

    23.2 Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a, Art. 7 DSGVO, Art. 6 Abs. 1 lit. f DSGVO
    in Verbindung mit gesetzlichen Vorgaben für werbliche Kommunikationen. Die Kontaktaufnahme erfolgt nur mit
    Einwilligung der Kontaktpartner oder im Rahmen der gesetzlichen Erlaubnisse und die verarbeiteten Daten werden
    gelöscht, sobald sie nicht erforderlich sind und ansonsten mit Widerspruch/ Widerruf oder Wegfall der
    Berechtigungsgrundlagen oder gesetzlicher Archivierungspflichten. <br/> <br/>

    Unser Newsletter enthält Informationen zu unseren Produkten, Angeboten, Aktionen und unserem Unternehmen.
    Hinweise zum Datenschutz, Widerruf, Protokollierung sowie der von der Einwilligung umfassten Erfolgsmessung,
    erhalten Sie in unserer Datenschutzerklärung. <br/> <br/>

    Versanddienstleister: Der Versand der Newsletter erfolgt mittels „MailChimp“, einer Newsletterversandplattform des
    US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die
    Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen: <a
      href="https://mailchimp.com/legal/privacy/" target="_blank">https://mailchimp.com/legal/privacy/</a>.
    The Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
    hierdurch eine Garantie, das europäisches Datenschutzniveau einzuhalten
    (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active" target="_blank">
    https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active</a>). <br/> <br/>

    Hinweis zu der Rechtsgrundlage: Bitte wählen Sie bei den Angaben zu den Rechtsgrundlagen die Variante für
    Deutschland oder Österreich aus. Bitte berücksichtigen Sie, dass in Österreich aufgrund einer Bestimmung des
    E-Commerce Gesetzes (ECG) die sog „ECG-Liste“ berücksichtigt werden muss. Diese Liste wird bei der
    Regulierungsbehörde für Telekommunikation und Rundfunk (RTR-GmbH) geführt: <a
      href="https://www.rtr.at/de/tk/TKKS_Spam" target="_blank">https://www.rtr.at/de/tk/TKKS_Spam</a>.
    Sie enthält jene E-Mail-Adressen, an die keine E-Mails gesendet werden dürfen. <br/> <br/>

    <h4>24. Einbindung von Diensten und Inhalten Dritter</h4>

    24.1. Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse
    an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
    f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
    oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”). Dies setzt immer voraus, dass
    die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
    nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich.
    Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung
    der Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als
    „Web Beacons“ bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die „Pixel-Tags“ können
    Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen
    Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische
    Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur
    Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden
    werden können. <br/> <br/>

    24.2. Die nachfolgende Darstellung bietet eine Übersicht von Drittanbietern sowie ihrer Inhalte, nebst Links zu
    deren Datenschutzerklärungen, welche weitere Hinweise zur Verarbeitung von Daten und, z.T. bereits hier genannt,
    Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten. <br/> <br/>

    – Falls unsere Kunden die Zahlungsdienste Dritter (z.B. PayPal oder Sofortüberweisung) nutzen, gelten die
    Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen Drittanbieter, welche innerhalb der jeweiligen
    Webseiten, bzw. Transaktionsapplikationen abrufbar sind. <br/> <br/>

    – Externe Schriftarten von Google, LLC., <a href="https://www.google.com/fonts" target="_blank">
      https://www.google.com/fonts</a> („Google Fonts“). Die Einbindung der
    Google Fonts erfolgt durch einen Serveraufruf bei Google (in der Regel in den USA). Datenschutzerklärung:
    <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>, Opt-Out:
    <a href="https://adssettings.google.com/authenticated" target="_blank">
      https://adssettings.google.com/authenticated</a>. <br/> <br/>

    – Landkarten des Dienstes „Google Maps“ des Drittanbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View,
    CA 94043, USA, gestellt. Datenschutzerklärung:
    <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a>,
    Opt-Out: <a href="https://www.google.com/settings/ads/" target="_blank">https://www.google.com/settings/ads/</a>.
    <br/> <br/>

    – Videos der Plattform „YouTube“ des Dritt-Anbieters Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
    94043, USA. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" target="_blank">
      https://www.google.com/policies/privacy/</a>, Opt-Out: <a
        href="https://www.google.com/settings/ads/" target="_blank">https://www.google.com/settings/ads/</a>.
    <br/> <br/>

    – Innerhalb unseres Onlineangebotes sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden
    angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94024, USA integriert. Wenn Sie in Ihrem
    Instagram – Account eingeloggt sind können Sie durch Anklicken des Instagram – Buttons die Inhalte unserer Seiten
    mit Ihrem Instagram – Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto
    zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
    Daten sowie deren Nutzung durch Instagram erhalten. Datenschutzerklärung:
    <a href="http://instagram.com/about/legal/privacy/" target="_blank">http://instagram.com/about/legal/privacy/</a>.
    <br/> <br/>

    – Wir verwenden Social Plugins des sozialen Netzwerkes Pinterest, das von der Pinterest Inc., 635 High Street, Palo
    Alto, CA, 94301, USA (“Pinterest”) betrieben wird. Wenn Sie eine Seite aufrufen die ein solches Plugin enthält,
    stellt Ihr Browser eine direkte Verbindung zu den Servern von Pinterest her. Das Plugin übermittelt dabei
    Protokolldaten an den Server von Pinterest in die USA. Diese Protokolldaten enthalten möglicherweise Ihre
    IP-Adresse, die Adresse der besuchten Websites, die ebenfalls Pinterest-Funktionen enthalten, Art und
    Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies.
    Datenschutzerklärung: <a href="https://about.pinterest.com/de/privacy-policy" target="_blank">
      https://about.pinterest.com/de/privacy-policy</a>. <br/> <br/>

    – Innerhalb unseres Onlineangebotes können Funktionen des Dienstes, bzw. der Plattform Twitter eingebunden
    (nachfolgend bezeichnet als „Twitter“). Twitter ist ein Angebot der Twitter Inc., 1355 Market Street, Suite 900,
    San Francisco, CA 94103, USA. Die Funktionen beinhalten die Darstellung unserer Beiträge innerhalb von Twitter
    innerhalb unseres Onlineangebotes, die Verknüpfung zu unserem Profil bei Twitter sowie die Möglichkeit mit den
    Beiträgen und den Funktionen von Twitter zu interagieren, als auch zu messen, ob Nutzer über die von uns bei
    Twitter geschalteten Werbeanzeigen auf unser Onlineangebot gelangen (sog. Conversion-Messung). Twitter ist
    unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
    Datenschutzrecht einzuhalten (<a
        href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active" target="_blank">
      https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active</a>).
    Datenschutzerklärung: <a href="https://twitter.com/de/privacy" target="_blank">https://twitter.com/de/privacy</a>.
    <br/> <br/>
  </div>
</template>

<script>
export default {
  name: "Privacy"
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
@import "../assets/scss/layout.scss";
.privacy-container {
  color: #444444;
  font-weight: 300;
  position: relative;
  padding: 150px 100px 50px;
  @include MQ(XS) {
    padding: 100px 10px 25px;
  }
  @include MQ(M) {
    padding: 150px 50px 50px;
  }
  @include MQ(S) {
    padding: 150px 50px 50px;
  }
  h1 {
    font-weight: 400;
    font-size: 45px;
    line-height: 48px;
    padding-bottom: 25px;
    @include MQ(XS) {
      text-align: left;
      font-size: 3.2vh;
      line-height: 3.4vh;
    }
    @include MQ(S) {
      font-size: 34px;
      line-height: 37px;
    }
    @include MQ(L) {
      font-size: 40px;
      line-height: 43px;
    }
    @include MQ(M) {
      font-size: 40px;
      line-height: 43px;
    }
  }
  a {
    text-decoration: none;
    color: #444444;
  }
}
</style>
